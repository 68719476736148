<template>
  <div class="techno-container">
    <div class="techno-list">
      <div v-for="(techno, index) in skills" :key="techno" class="techno-entry box-shadow">
        {{techno}}
        <div class="delete-x" v-if="editing" @click="() => deleteTech(index)">
          <img :src="require('@/assets/pictos/picto_close.svg')"/>
        </div>
      </div>
      <div class="btn-green edit-button" @click="toggleEdit">
        {{this.editing? 'Submit': 'Edit'}}
      </div>
    </div>
    <big-add :label="'Add New Skill'" :add="toggleAdd" v-if="!this.adding" />
    <company-attr v-model="newTech" label="New Skill" :start-edit="true" v-if="this.adding"
      @submitted="this.addNew"
      @editing="() => {this.adding = false; this.newTech = ''}"/>
  </div>
</template>

<script>
import BigAdd from '@/components/UniversalComponents/Settings/_addButtonBig.vue';
import CompanyAttr from '@/components/EmployeeComponents/CompanySettings/companyAttr.vue';
import Technos from '@/components/TalentComponents/talentTechnos/talentTechnoList';

export default {
  name: 'SkillSelect',
  components: {
    BigAdd,
    CompanyAttr,
  },
  props: {
    skillList: {
      type: Array,
      default: () => [],
    },
    update: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      skills: [],
      editing: false,
      adding: false,
      newTech: '',
      technoList: Technos.map((t) => t.text),
    };
  },
  methods: {
    updateTech() {
      this.skills = [...this.skillList];
    },
    deleteTech(index) {
      this.skills.splice(index, 1);
    },
    toggleEdit() {
      if (this.editing) {
        this.update(this.skills).then(() => {
          this.updateTech();
        });
      }
      this.editing = !this.editing;
      this.adding = false;
    },
    toggleAdd() {
      this.adding = !this.adding;
      this.editing = true;
    },
    addNew() {
      this.skills.push(this.newTech);
      this.newTech = '';
    },
  },
  mounted() {
    this.updateTech();
  },
};
</script>

<style lang="scss" scoped>
.techno-container{
  width: 86%;
  position: relative;
  margin-bottom: 1vh;
  .add-button-big{
    width: 100% !important;
  }
  .company-attr{
    width: 98% !important;
  }

  .techno-entry{
    width: auto !important;
  }
  // .techno-list{
  //   background-color: #f8f8f8;
  //   display: flex;
  //   width: calc(100% - (5vw + 4vh));
  //   flex-wrap: wrap;
  //   padding: 2vh;
  //   padding-right: calc(5vw + 2vh);
  //   position: relative;
  //   min-height: 8vh;
  //   margin-bottom: 2vh;

  //   .edit-button{
  //     position: absolute;
  //     right: 1vh;
  //     bottom:1vh;
  //     border-radius: 5px !important;

  //   }
  //   // .techno-entry{
  //   //   font-size: 1.25vh;
  //   //   font-weight: 600;
  //   //   min-width: 5vw;
  //   //   background-color: #fff;
  //   //   text-align: center;
  //   //   padding-left: .5vw;
  //   //   padding-right: .5vw;
  //   //   height: 4vh;
  //   //   line-height: 4vh;
  //   //   margin-right: 1vw;
  //   //   margin-bottom: 1vh;
  //   //   position: relative;
  //   //   border-radius: 5px;
  //   //   &last-child{
  //   //     margin-right: 0;
  //   //   }
  //   //   .delete-x{

  //   //     cursor: pointer;
  //   //     position: absolute;
  //   //     right: -2vh;
  //   //     top: -1.5vh;
  //   //     justify-content: center;
  //   //     width: 3vh;
  //   //     height: 3vh;
  //   //     padding: 5px;
  //   //     border-radius: 50%;
  //   //     img{
  //   //       width: 200%;
  //   //       height: auto;
  //   //       position: relative;
  //   //       top: 50%;
  //   //       left: 50%;
  //   //       transform: translateX(-50%) translateY(-50%);

  //   //     }
  //   //   }
  //   // }
  // }
}

</style>
