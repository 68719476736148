<template>
<div class="background-list">

  <div id="backgroundlist" class="wpadding elem-flex mh665 flex-overflow-column">
    <div class="contentbox marginB radius" >
      <div class="subtitle-page no-margin wother">
        Professional Background
        <div class="other">
          <div
            v-if="this.editable"
            class="small btn-green radius-small"
            @click="() => addNew('professionalBG')"
          >Add New</div>
        </div>
      </div>
      <loading v-if="loading" />
      <div class="cont-entry cont_experiences_entry" v-else>
        <resume-entry
          v-for="(proEntry, index) in professionalEntries"
          :key="proEntry._id"
          :type="'professionalBG'"
          :objectData="proEntry"
          :editable="editable"
          :index="index"
        />
      </div>
    </div>
    <div class="contentbox marginB radius">
      <div class="subtitle-page no-margin wother">
        Education Background
        <div class="other">
          <div
            v-if="this.editable"
            class="small btn-green radius-small"
            @click="() => addNew('academicBG')"
          >Add New</div>
        </div>
      </div>
      <loading v-if="loading" />
      <div class="cont-entry cont_academic_entry" v-else>
        <resume-entry
          v-for="(academicEntry, index) in academicEntries"
          :key="academicEntry._id"
          :type="'academicBG'"
          :objectData="academicEntry"
          :editable="editable"
          :index="index"
        />
      </div>
    </div>
    <div class="contentbox radius">
      <div class="subtitle-page no-margin wother">
        Projects
        <div class="other">
          <div
            v-if="this.editable"
            class="small btn-green radius-small"
            @click="() => addNew('projects')"
          >Add New</div>
        </div>
      </div>
      <loading v-if="loading" />
      <div class="cont-entry cont_project_entry" v-else>
        <resume-entry
          v-for="(projectEntry, index) in projectEntries"
          :key="projectEntry._id"
          :type="'projects'"
          :objectData="projectEntry"
          :editable="editable"
          :index="index"
        />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Loading from '@/components/utilityPartials/_loading.vue';
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';

export default {
  name: '',
  components: {
    Loading,
    ResumeEntry,
  },
  props: ['talentInfo'],
  data() {
    return {
      loading: true,
      bgData: [],
      // talentInfo: {},
      editable: true,
    };
  },
  computed: {
    academicEntries() {
      if (this.editable) {
        const elemAcaEditable = this.$store.getters['talent/academicBG'];
        elemAcaEditable.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
        return elemAcaEditable;
        // return this.$store.getters['talent/academicBG'];
      }
      const elemAca = this.talentInfo.academicBG;
      elemAca.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemAca;
      // return this.talentInfo.academicBG;
    },
    projectEntries() {
      if (this.editable) {
        const elemProjectEditable = this.$store.getters['talent/projects'];
        elemProjectEditable.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
        return elemProjectEditable;
        // return this.$store.getters['talent/projects'];
      }
      const elemProject = this.talentInfo.projects;
      elemProject.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemProject;
      // return this.talentInfo.projects;
    },
    professionalEntries() {
      if (this.editable) {
        const elemProEditable = this.$store.getters['talent/professionalBG'];
        elemProEditable.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
        return elemProEditable;
        // return this.$store.getters['talent/professionalBG'];
      }
      const elemPro = this.talentInfo.professionalBG;
      elemPro.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemPro;
      // return this.talentInfo.professionalBG;
    },
  },
  mounted() {
    if (this.$store.state.talent.resumeRetrieved) {
      this.loading = false;
    } else {
      this.$store.dispatch('talent/getResume').then(() => {
        this.loading = false;
      });
    }
  },
  methods: {
    addNew(type) {
      this.$store.dispatch('talent/addBlankResume', type);
    },
  },

};
</script>

<style lang="scss">
.cont-entry{
    // margin-top: 1vh;
    .ResumeEntry{
      .infos{
        .ap-entry{
          margin-bottom: 0 !important;
        }
        .cont-submit{
          margin-top: 1vh;
          margin-bottom: 2vh;
        }
      }
      margin-bottom: 1vh;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .wpadding{
    margin-bottom: 1vh;
    &#backgroundlist{
      margin-bottom: 0;
    }
  }
  #backgroundlist{
    position: relative;
    overflow: hidden;
  }
  .background-list{
    position: relative;
    .subtitle-page{
      margin-bottom: 1vh !important;
    }
    .view-profile{
      position: absolute;
      top: 25px;
      right: 0;
      .btn-green{
        font-size: $classic_fs;
      }
    }
  }
  .cont-employee-list{
    // .inner-list-employee{
    //   width: 100%;
    //   display: flex;
    //   flex-direction: row;
    //   align-items: flex-start;
    //   justify-content: flex-start;
    //   flex-wrap: wrap;
    // }
    .cont-entry{
      display: flex;
      flex-direction: column-reverse;
      margin-top: 1vh;
    }
  }
</style>
