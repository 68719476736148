<template>
  <div class="talent-resume">
    <div class="info radius">
      <div class="info-title">
        <div class="picto">
          <img :src="talent.profilePicture">
        </div>
        <div class="name-tag">
          <div class="name">
            {{nameString}}
          </div>

        </div>
      </div>
      <div class="info-attr">

        <div class="elem">
          <div class="picto">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="txt">
            {{talent.location}}
          </div>
        </div>
        <div class="elem">
          <div class="picto">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="txt">
            <a :href="talent.webSite">{{talent.webSite}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="bio radius" id="bio-talent" :class="{expanded: bioExpand }">
      <div class="bio-header">
        About {{nameString}} ?
      </div>
      <div v-html="talent.description" />
      <div class="expand-button" :class="bioExpand ? 'open': 'closed'" @click="toggleExpand"
        v-if="overflow"
      >
        <img :src="require('@/assets/pictos/picto_arrow_round.svg')" />
      </div>
    </div>
    <tech-slider :techList="talent.technologies" :label="'Technologies'"/>
    <tech-slider :techList="talent.skills" :skills="true" :label="'Skills'"/>

  </div>
</template>

<script>
import { mapState } from 'vuex';

import TechSlider from '@/components/EmployeeComponents/CompanySettings/technologySlider.vue';

export default {
  name: 'TalentProfileSmall',
  components: {
    TechSlider,
  },
  computed: {
    ...mapState('talent', {
      talent: (state) => state.talentInfo,
      loading: (state) => state.loadingInfo,
    }),
    nameString() {
      return `${this.talent.name.firstName} ${this.talent.name.lastName}`;
    },
  },
  data() {
    return {
      bioExpand: false,
      overflow: true,
    };
  },
  methods: {
    toggleExpand() {
      this.bioExpand = !this.bioExpand;
    },
    checkOverflow() {
      const element = document.getElementById('bio-talent');
      if (element) {
        return element.scrollHeight > element.clientHeight;
      }
      return true;
    },
  },
  mounted() {
    this.overflow = this.checkOverflow();
  },
  watch: {
    'talent.description': function () {
      this.overflow = this.checkOverflow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.talent-resume{
  width: 100%;
  position: relative;
  .info{
   border: 0.1vh #8c28e1 solid;
   width: calc(100% - 2.2vh);
   position: relative;
   padding: 1vh;
    .info-title{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      .name-tag{
        display:flex;
        flex-direction: column;
        width: calc(100% - 7.5vh);
        .name{
          font-size: 2.4vh;
          margin-bottom: 0.5vh;
          color:#8c28e1;
          font-weight: 600;
          p{
          }
        }
        .slogan{
          font-size: 1.25vh;
          font-weight: 600;
        }
      }
      .picto{
        position: relative;
        width: 6vh;
        height: 6vh;
        overflow: hidden;
        border-radius: 50%;
        background-color: #151515;
        color: white;
        font-size: 2vh;
        line-height: 2vh;
        border: 1px solid white;
        margin-right: 1vh;

        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 100%;
        }
      }
    }
    .info-attr{
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 1vh;
      justify-content: space-between;
      .elem{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1vh;
        margin-bottom: 1.5vh;

        .picto{
          position: relative;
          width: 1.5vw;
          font-size: 0;
          margin-right: 0.5vh;
          img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 150%;
          }
            .small{
              width: 100% !important ;
            }
        }
        .txt{
          font-size: 1.5vh;
          line-height: 1.5vh;
          text-align: center;
        }
      }
    }
  }
  .bio{
    padding: 1vh 2vh 2vh 1vh;
    height: 10vh;
    width: calc(100% - 3vh);
    background-color: #160033;
    overflow: hidden;
    font-size: 1.5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    // border: 0.1vh #8c28e1 solid;
    color: #FFF;
    position: relative;
    &.expanded{
      height: fit-content !important;
    }
    .bio-header{
      color: #48ffaf;
      margin-bottom: 1vh;
      font-size: 2vh;
    }
    .expand-button{
      position: absolute;
      bottom:1vh;
      right: 1vh;
      cursor: pointer;
      transition: all .2s;
      &:hover{
        opacity: 0.8;
      }
      &.open{
        transform: rotate(180deg);
      }
    }

  }
}
</style>
