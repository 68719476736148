<template>
  <div class="elem-flex">
    <div class="sub-section">
      <div class="subtitle-page no-margin wother">
        Certifications
        <div class="other">
          <div class="btn-green radius-small small" @click="addNew">
            Add New Certification
          </div>
        </div>
      </div>
      <div class="list-cert" v-if="adding || certifications.length > 0">
        <certification v-for="certification in certifications"
          :key="certification._id"
          :certification="certification"
          :editable="true"
        />

        <certification v-if="adding"
          :certification="adding"
          :editable="true"
          @editClosed="editClosed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Certification from './_certification.vue';

export default {
  name: 'settingsUsers',
  components: {
    Certification,
  },
  data() {
    return {
      adding: false,
    };
  },
  computed: {
    certifications() {
      const certifs = this.$store.getters['talent/certifications'];
      return certifs.sort((a, b) => new Date(a.issueDate) - new Date(b.issueDate));
    },
  },

  methods: {
    addNew() {
      this.adding = {
        name: null,
        organization: null,
        issueDate: null,
        expirationDate: null,
        url: null,
      };
    },
    editClosed() {
      this.adding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .list-cert{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top:20px;

}
</style>
