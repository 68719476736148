<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Profile
          <div class="view-profile">
            <router-link class="btn-green radius" target='_blank' :to="`/talent/${talent._id}`">
              View As a Recruiter
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-container  main-cont-talent" id="scrollTalent">
      <div class="talent-preview">
        <div class="select-group">
          <div class="option" @click="() => {this.previewSelect = 'Profile'}"
            :class="{selection: this.previewSelect === 'Profile'}"
            >
            Profile
            <div class="underline" v-if=" this.previewSelect === 'Profile'" />
          </div>
          <div class="option" @click="() => {this.previewSelect = 'Certifications'}"
            :class="{selection: this.previewSelect === 'Certifications'}"
            >
            Certifications
            <div class="underline" v-if=" this.previewSelect === 'Certifications'" />
          </div>
        </div>
        <div class="content-cont" id="left-cont">
          <talentCertifications
          v-if="this.previewSelect ==='Certifications'"></talentCertifications>
          <talent-profile-small v-if="this.previewSelect ==='Profile'" />
        </div>
      </div>
      <div class="settings">
        <div class="select-group">
          <div v-for="section in this.optionSections"
            :key="section"
            class="option"
            @click="() => {currentSection = section; goTop();}"
            :class="{selection: currentSection === section}"
            >
            {{section}}
            <div class="underline" v-if="section === currentSection" />
          </div>
        </div>
        <div class="content-cont" id="settings-container-talent" >
          <template v-if="currentSection === 'General' && loaded" >
            <setting-attr v-model="talent.name.firstName" :label="'First Name'" />
            <setting-attr v-model="talent.name.lastName" :label="'Last Name'" :darker="true"  />
            <setting-attr v-model="talent.location" :label="'Location'" />
            <setting-attr v-model="talent.website" :label="'Website'" :darker="true" />
            <setting-attr v-model="talent.description" :label="'Bio'" :type="'html'" />
            <setting-attr v-model="talent.profilePicture" :label="'Profile Picture'"
              :type="'proPic'" :darker="true"
            />
          </template>
          <tech-list v-if="currentSection === 'Technologies'"
            :technologyList="this.talentInfo.technologies" :update="updateTech"
          />
          <skill-list v-if="currentSection === 'Skills'"
            :skillList="this.talentInfo.skills" :update="updateSkills"
          />
          <BackgroundList :talentInfo="talentInfo" v-if="this.currentSection ==='Resume'"/>
          <template v-if="currentSection === 'Social'">
            <company-social v-for="(social, index) in socials" :key="index"
              v-model="socials[index]"
              :remove="() => deleteEntry(index, 'socials')"
              :startEdit="!social.site && !social.link"
              @submitted="updateSocials"
            >
            </company-social>
            <add-button-big :add="this.addSite" :label="'Add New Social'"/>

          </template>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import '@/assets/scss/settings/selector.scss';
import talentCertifications from '@/components/TalentComponents/talentSettings/talentCertifications.vue';
import settingAttr from '@/components/UniversalComponents/Settings/settingAttr.vue';
import scrollInit from '@/modules/mixins/perfectScrollInit';
import techList from '@/components/UniversalComponents/Settings/_technologies.vue';
import skillList from '@/components/UniversalComponents/Settings/_skills.vue';
import BackgroundList from '@/components/TalentComponents/talentSettings/backgroundList.vue';
import CompanySocial from '@/components/EmployeeComponents/CompanySettings/companySocial.vue';
import AddButtonBig from '@/components/UniversalComponents/Settings/_addButtonBig.vue';
import TalentProfileSmall from '@/views/Talent/TalentProfile/talentProfileSmall.vue';

export default {
  name: 'EditTalent',
  components: {
    talentCertifications,
    settingAttr,
    techList,
    skillList,
    BackgroundList,
    CompanySocial,
    AddButtonBig,
    TalentProfileSmall,
  },
  data() {
    const data = JSON.parse(JSON.stringify(this.$store.getters['user/userData']));
    return {
      // talentInfo: this.$store.state.talent.talentInfo,
      talent: {},
      socials: [],
      optionSections: ['General', 'Skills', 'Resume', 'Technologies', 'Social'],
      previewSelect: 'Profile',
      currentSection: 'General',
      userData: data,
      sending: false,
      editable: true,
      loaded: false,

    };
  },
  computed: {
    ...mapState('talent', {
      talentInfo: (state) => state.talentInfo,
      loading: (state) => state.loadingInfo,
    }),
    ...mapState('user', ['_id']),
  },
  mounted() {
    if (Object.keys(this.$store.state.talent.talentInfo).length > 0) {
      this.getInfo();
    } else {
      this.$store.dispatch('talent/getTalentInfo').then(() => {
        this.getInfo();
      });
    }
    scrollInit('#settings-container-talent');
    scrollInit('#left-cont');
  },
  methods: {
    getInfo() {
      this.talent = {
        _id: this.talentInfo._id,
        name: {
          firstName: this.talentInfo.name.firstName,
          lastName: this.talentInfo.name.lastName,
        },
        jobWanted: this.talentInfo.jobWanted,
        location: this.talentInfo.location,
        description: this.talentInfo.description,
        website: this.talentInfo.website,
        profilePicture: this.talentInfo.profilePicture,
      };
      this.socials = [...this.talentInfo.socials];
      this.loaded = true;
    },
    submit(updateBody) {
      this.$store.dispatch('talent/postTalentInfo', updateBody).then(() => {
        this.sending = false;
      });
    },
    updateTech(update) {
      return this.$store.dispatch('talent/postTalentInfo', { technologies: update });
    },
    updateSkills(update) {
      return this.$store.dispatch('talent/postTalentInfo', { skills: update });
    },
    updateSocials() {
      return this.$store.dispatch('talent/postTalentInfo', { socials: this.socials });
    },
    goTop() {
      const element = document.getElementById('settings-container-talent');
      this.$nextTick(() => {
        element.scrollTop = 0;
      });
    },
    addSite() {
      if (!this.maxSocials) {
        this.socials.push({ site: '', link: '' });
      }
    },
    deleteEntry(index) {
      this.socials.splice(index, 1);
      this.updateSocials();
    },
  },

  watch: {
    talent: {
      deep: true,
      handler(newVal) {
        const editBody = {};
        Object.keys(newVal).forEach((key) => {
          if (newVal[key] !== this.talentInfo[key]) {
            editBody[key] = newVal[key];
          }
        });

        if (Object.keys(editBody).length > 0) {
          this.submit(editBody);
        }
      },

    },
  },
};
</script>

<style lang="scss">
.main-cont-talent{
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  .talent-preview{
    width: 25%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-right: 5%;
  }
  .settings{
    width: 70%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

  }
  .content-cont{
    width: calc(100% - 4vh);
    position: relative;
    flex-direction: column;
    padding: 2vh;
    height: 85%;
    justify-content: space-between;
    overflow-y: hidden;

    .next-section{
      position: relative;
      display: block;
      background-color: #8c28e1;
      color: #FFF;
      transition: background-color 0.3s;
      font-size: 1.4vh;
      padding: 1vh 1.5vh;
      cursor: pointer;
      width: 13%;
      margin-left: 75%;
      border-radius: 5px;
      cursor: pointer;

    }
  }

}
.view-profile{
    position: absolute;
    top: 15px;
    right: 0;
  }
</style>
